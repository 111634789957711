<template>
	<button class="nav__btn mint__btn btn"
		@click="connect"
	>
		<p v-if="!isConnect">Connect wallet</p>
		<p v-if="isConnect">Disconnect</p>
	</button>
</template>

<script>
import wallets from '@/wallets/wallets'

export default {
	name: 'ConnectWalletButton',

	props: {
		isConnect:  {
			type: Boolean,
			default: false
		},
	},

	emits: ['connect-status'],

	methods: {
		async connect() {
			if(this.isConnect) {
				wallets.disconnect()
				this.$emit('connect-status', false)
				return
			}
			let rezult = await wallets.connect()

			if(!rezult) {
				console.error('Wallet connect error')
				return
			}
			this.$emit('connect-status', true)
		},
	}
}
</script>