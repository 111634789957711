export default {
	TEST_MODE: true,
	SMART_CONTRACT_ADDRESS: '0x2a02Ac82A1ed6eB4b75eBCEfB71612B117fD739D',
	E_MAIL: 'info@foxtrove.org',
	GITHUB_URL: 'https://github.com/YagaLabs-Team',

	MAXIMUM_NFT: 10000,

	defaultWallet: 'MetaMask',

	defaultPrice: 0.05,
	defaultTotalMinted: 300,

	menuLinks: {
		About: '/about.html',
		Foxcoin: '/coin.html',
		Provenance: '/prov.html',
		Gallery: '#',
		Mint: '/mint.html',
		Terms: '/terms.html'
	},

	socialLinks: {
		Opensea: '#',
		Blur: '#',
		Twitter: 'https://twitter.com/foxtrovenft',
		Youtube: 'https://www.youtube.com/@YagaLabs',
		Instagram: 'https://www.instagram.com/foxtrovenft/',
	},
}