<template>
	<div class="container">
		<div class="nav__box d-flex">
			<div class="nav__left d-flex">
				<a href="/" class="nav__logo"><img src="/img/logo.svg" alt=""></a>

				<ul class="nav__list d-flex">
					<li class="nav__item">
						<a :href="config.menuLinks.About" class="nav__link">About</a>
					</li>
					<li class="nav__item">
						<a :href="config.menuLinks.Foxcoin" class="nav__link">Foxcoin</a>
					</li>
					<li class="nav__item">
						<a :href="config.menuLinks.Provenance" class="nav__link">Provenance</a>
					</li>
					<li class="nav__item">
						<a :href="config.menuLinks.Gallery" class="nav__link">Gallery</a>
					</li>
				</ul>
			</div>

			<div class="nav__right d-flex">
				<SocialsComp/>

				<a class="nav__btn btn"
					v-if="!isMint"
					:href="config.menuLinks.Mint"
				>
					<p>mint now</p>
				</a>

				<ConnectWalletButton
					v-if="isMint"
					:is-connect="isConnect"
					@connect-status="(status) => { $emit('connect-status', status) }"
				/>
			</div>

			<div class="menu-btn" @click="isOpenMenu = !isOpenMenu">
				<span></span>
				<span></span>
				<span></span>
			</div>

			<div class="menu" :class="{ active: isOpenMenu }">

				<a class="nav__btn btn"
					v-if="!isMint"
					:href="config.menuLinks.Mint"
				>
					<p>mint now</p>
				</a>

				<ConnectWalletButton
					v-if="isMint"
					:is-connect="isConnect"
					@connect-status="(status) => { $emit('connect-status', status) }"
				/>

				<ul class="nav__list">
					<li class="nav__item">
						<a :href="config.menuLinks.About" class="nav__link">About</a>
					</li>
					<li class="nav__item">
						<a :href="config.menuLinks.Foxcoin" class="nav__link">Foxcoin</a>
					</li>
					<li class="nav__item">
						<a :href="config.menuLinks.Provenance" class="nav__link">Provenance</a>
					</li>
					<li class="nav__item">
						<a :href="config.menuLinks.Gallery" class="nav__link">Gallery</a>
					</li>
				</ul>

				<SocialsComp/>
			</div>
		</div>
	</div>
</template>

<script>
import config from '@/config'
import SocialsComp from '@/components/socials-comp'
import ConnectWalletButton from '@/components/connect-wallet-button'

export default {
  name: 'nav-comp',

	emits:['connect-status'],

	props: {
		isMint: {
			type: Boolean,
			default: false
		},
		isConnect:  {
			type: Boolean,
			default: false
		},
	},

	components: {
		SocialsComp,
		ConnectWalletButton,
	},

	data() {
		return {
			config: config,
			isOpenMenu: false,
		}
	}
}
</script>