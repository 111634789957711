import config from '@/config'
import Web3 from 'web3'
import detectEthereumProvider from '@metamask/detect-provider'
import configContracts from '@/configContracts'

const provider = await detectEthereumProvider()
const web3 = new Web3(provider)

const contractData = config.TEST_MODE ? configContracts.testNet : configContracts.mainNet
contractData.chainId = Web3.utils.toHex(contractData.chainId)
const contract = new web3.eth.Contract(contractData.ABI, contractData.contract)

export default {
	connect: async function() {
		if(!this.checkWallet()) {
			console.error('MetaMask is undefined')
			return false
		}

		if(!provider.selectedAddress) {
			console.error('Please login in to wallet!')
		}
    const accounts = await provider.request({ method: 'eth_requestAccounts' })

		if(!accounts) return false


		if(provider.chainId !== contractData.chainId) {
			try {
				const ansver = await provider.request({
						method: 'wallet_switchEthereumChain',
						params: [{ chainId: contractData.chainId }],
				})
				console.log(`ansver: ${ansver}`)
			} catch (err) {
				console.log(err)

				try {
					await provider.request({
						method: 'wallet_addEthereumChain',
						params: [{
							chainId: contractData.chainId,
							chainName: contractData.netName,
							rpcUrls: [contractData.netRpc],
							blockExplorerUrls: [contractData.netLink],
							nativeCurrency: {
								symbol: contractData.symbol,
								decimals: contractData.decimals
							}
						}]
					})
				} catch (err) {
					console.log(err)
					return false
				}
			}
		}

		return accounts[0]
	},

	checkWallet: function() {
		if (typeof window.ethereum === 'undefined' || !window.ethereum.isMetaMask) return false
		return true
	},

	fromWei: function(amount, units = 'ether') { // Преобразование из WEI
		return Web3.utils.fromWei(String(amount), units)
	},

	toWei: function(amount, units = 'ether') { // Преобразование в WEI
		return Web3.utils.toWei(String(amount), units)
	},

	contract: function(){
		return contract
	},

	web3: function() {
		return web3
	}
}