<template>
	<div class="nav__socials d-flex">
		<a class="nav__site" target="_blank" :href="config.socialLinks.Opensea">
			<img src="/img/a1.svg" alt="">
		</a>
		<a class="nav__site" target="_blank" :href="config.socialLinks.Blur">
			<img src="/img/a2.svg" alt="">
		</a>
		<a class="nav__site" target="_blank" :href="config.socialLinks.Twitter">
			<img src="/img/a3.svg" alt="">
		</a>
		<a class="nav__site" target="_blank" :href="config.socialLinks.Youtube">
			<img src="/img/a4.svg" alt="">
		</a>
		<a class="nav__site" target="_blank" :href="config.socialLinks.Instagram">
			<img src="/img/a5.svg" alt="">
		</a>
	</div>
</template>

<script>
import config from '@/config'

export default {
  name: 'socials-comp',

	data() {
		return {
			config: config,
		}
	}
}
</script>