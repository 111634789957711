export default {
	saveWallet: (userWalletName, userWalletID) => {
		localStorage.setItem('userWalletName', userWalletName)
		localStorage.setItem('userWalletID', userWalletID)
	},

	getWallet: () => {
		const userWalletName = localStorage.getItem('userWalletName')
		const userWalletID = localStorage.getItem('userWalletID')

		if(!userWalletName || !userWalletID) { return false }
		return {
			userWalletName,
			userWalletID
		}
	},

	removeWallet: () => {
		localStorage.removeItem('userWalletName')
		localStorage.removeItem('userWalletID')
	},
}