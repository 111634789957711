<template>
	<div class="container">
		<div class="footer__box d-flex">
			<div class="footer__left">
				<img src="/img/footer-logo.png" alt="" class="footer__logo">
				<p>
					Copyright 2023. Made with by YagaLabs. <br>
					<a :href="config.menuLinks.Terms" target="blank">Terms&Conditions</a>
				</p>
			</div>

			<div class="footer__content">
				<p class="footer__title">
					VERIFIED SMART CONTRACT ADDRESS:
				</p>

				<div class="footer__block d-flex"
					@click="copyToBufer"
				>
					<input type="text" class="footer__link"
						ref="contractinput"
						v-model="config.SMART_CONTRACT_ADDRESS"
					>
					<button class="footer__copy" title="Copy contract address"
						@click.stop="copyToBufer"
					><svg class="icon"><use xlink:href="/img/svg-sprite.svg#copy"></use></svg></button>
				</div>
			</div>

			<div class="footer__right d-flex">
				<div class="footer__item d-flex">
					<img src="/img/f1.svg" alt="">
					<a :href="`mailto:${config.E_MAIL}`" class="footer__mail">{{ config.E_MAIL }}</a>
				</div>

				<div class="footer__item d-flex">
					<img src="/img/f2.svg" alt="">
					<a :href="config.GITHUB_URL" target="_blanc" class="footer__mail">Github</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import config from '@/config'

export default {
  name: 'footer-comp',

	data() {
		return {
			config: config,
		}
	},

	methods: {
		copyToBufer() {
			let copyTextarea = document.createElement("textarea")
			copyTextarea.style.position = "fixed"
			copyTextarea.style.opacity = "0"
			copyTextarea.textContent = this.config.SMART_CONTRACT_ADDRESS
			document.body.appendChild(copyTextarea)
			copyTextarea.select()
			document.execCommand("copy")
			document.body.removeChild(copyTextarea)
			this.$refs.contractinput.select()
		}
	}
}
</script>