import config from '@/config'
import MetaMask from '@/wallets/MetaMask'
import storadge from '@/localStoradge'


const wallets =  {

	userWalletName: false,

	userWalletID: false,

	userWallet: false,

	setConnectWallet: function() {																		console.log('wallets.js: setConnectWallet()')
		storadge.saveWallet(this.userWalletName, this.userWalletID)
	},

	checkConnectWallet: async function() {														console.log('wallets.js: checkConnectWallet()')
		let status = storadge.getWallet()

		if(status) {
			return await this.connect(status.userWalletName)
		}
		return false
	},

	removeConnectWallet: function() {																	console.log('wallets.js: removeConnectWallet()')
		storadge.removeWallet()
	},

	setProvider: function(currentWallet = config.defaultWallet) { 		console.log('wallets.js: setProvider()')
		this.userWalletName = currentWallet

		switch(currentWallet) {
			case 'MetaMask':
				this.userWallet = MetaMask
				break
			default:
				this.userWallet = MetaMask
				break
		}
	},

	resetProvider: function() {																				console.log('wallets.js: resetProvider()')
		this.userWallet = false
		this.userWalletName = false
		this.userWalletID = false
		this.removeConnectWallet()
	},

	connect: async function(currentWallet = config.defaultWallet) { 	console.log('wallets.js: connect()')
		this.setProvider(currentWallet)
		let rezult = await this.userWallet.connect()

		if(!rezult) {
			this.resetProvider()
			return false
		}
		this.userWalletID = rezult
		this.setConnectWallet()
		return rezult
	},

	disconnect: function() {																					console.log('wallets.js: disconnect()')
		this.resetProvider()
	},

	contractCallMethod: async function(fnName) {											console.log('wallets.js: contractCallMethod()')
		try {
			return await this.userWallet.contract().methods[fnName]().call()
		} catch(error) {
			console.error(`Error in: ${fnName}() wallets.js`)
			console.error(error)
		}
		return false
	},

	transactionConfirmation: async function(transactionHash){ 				console.log('wallets.js: transactionConfirmation()')
		let counter = 0
		let status = false
		const wallet = this.userWallet

		const confirmation = async function() {
			try {
				const result = await wallet.web3().eth.getTransactionReceipt(transactionHash)

				if(result && 'status' in result) return result.status == 1n
				return false
			} catch(error) {
				console.error('Error in: transactionConfirmation() wallets.js')
				console.error(error)
				return false
			}
		}

		let promise = new Promise(function(resolve) {
			const interval = setInterval(async function() {
				counter++
				status = await confirmation()

				if(status || counter > 100) {
					clearInterval(interval)
					resolve(status)
				}
			},500)
		})

		return promise.then(
			function(status) {
				return status
			}
		)

	},

	getPrice: async function() {																			console.log('wallets.js: getPrice()')
		let rezult = await this.contractCallMethod('price')

		if(rezult) rezult = this.userWallet.fromWei(rezult)
		return rezult
	},

	getMinted: async function() {																			console.log('wallets.js: getMinted()')
		let rezult = await this.contractCallMethod('minted')

		if(rezult) rezult = this.userWallet.fromWei(rezult)
		return rezult
	},

	mint: async function(count, summ) {																console.log('wallets.js: mint()')
		summ = this.userWallet.toWei(summ)
		try {
			let transaction = await this.userWallet.contract().methods.mint(count).send({ from: this.userWalletID, value: summ, maxPriorityFeePerGas: null, maxFeePerGas: null })

			if(!transaction || !('transactionHash' in transaction)) return false
			return await this.transactionConfirmation(transaction.transactionHash)
		} catch(error) {
			console.error(`Error in: mint() wallets.js`)
			console.error(error)
		}
		return false
	}
}


export default wallets
